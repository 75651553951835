import Vue from 'vue'
import vueSmoothScroll from 'vue2-smooth-scroll'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueRouter from 'vue-router'
import App from './App.vue'

Vue.config.productionTip = false

Vue.use(vueSmoothScroll)
Vue.use(ElementUI)
Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    component: () => import('@/views/RoadrunnerLanding.vue'),
  },
  {
    path: '/terms-and-conditions',
    component: () => import('@/views/TermsAndConditions.vue'),
  },
  {
    path: '/privacy-policy',
    component: () => import('@/views/PrivacyPolicy.vue'),
  },
  {
    path: '/gifting-services',
    component: () => import('@/views/GiftingServices.vue'),
  },
  {
    path: '/merchantadminpanel-login',
    component: () => import('@/views/MerchantAdmin/Login.vue'),
  },
  {
    path: '/merchantadminpanel-add-store',
    component: () => import('@/views/MerchantAdmin/AddStore.vue'),
  },
  {
    path: '/merchantadminpanel-active-orders',
    component: () => import('@/views/MerchantAdmin/ActiveOrders.vue'),
  },
  {
    path: '/merchantadminpanel-logout',
    component: () => import('@/views/MerchantAdmin/Login.vue'),
  },
  { path: '', redirect: '/home/' },
  { path: '*', redirect: '/home/' },
]

export const router = new VueRouter({
  mode: 'history',
  scrollBehavior: function (to) {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes,
})

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
